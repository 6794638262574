import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';
import balanceText from 'balance-text';
import Captcha from './lib/captcha';
import {
  rLog,
  initTooltips,
  browserDetector,
  fetchPagePathFromPageID,
} from './lib/utils';
import CompanyAdmin from './lib/CompanyAdmin';
import Transition from './lib/ui/transitions';

// This starts all the initial Javascript for Recognize and checks if there is an error. If so, it puts it in a hot key for customer to view and send to us.
export default function(pages) {
  var errors = [],
    errorHTMLWrapper = document.createElement("div");


  // Check if reloadOnBack is set that is initialized from static error pages
  if (R.reloadOnBack) {
     return window.location.reload();
  }

  // Initialize R.reloadOnBack if it has a meta tag with reload-on-back set to true
  // This is used to reload the page when the user navigates back from the static error page
  var reloadOnBack = $('meta[name="reload-on-back"]');
  if (reloadOnBack.length > 0 && reloadOnBack.attr('content') === 'true'){
    R.reloadOnBack = true;
  }

  var sanitizedLocation = DOMPurify.sanitize(window.location.href); errorHTMLWrapper.innerHTML = "<a href='javascript://' class='btn btn-secondary me-2' onclick=\"window.copyToClipboardRecognizeError('#recognize-secret-errors')\">Copy</a><a href='"+sanitizedLocation+"' class='btn btn-light' data-turbo='false'>Reload</a><hr><h4>"+navigator.userAgent+", "+navigator.language+"</h4>";

  errorHTMLWrapper.className = 'd-none';
  errorHTMLWrapper.id = 'recognize-secret-errors';

  window.addEventListener('load', function() {
    document.body.appendChild(errorHTMLWrapper);
  });

  window.copyToClipboardRecognizeError = function(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text().replace('CopyReload', '')).select();
    document.execCommand("copy");
    $temp.remove();
  };

  try {
    init(pages);
    $document.trigger('recognizeInitComplete');
  } catch(e) {
    var errorKeys = [69, 17, 18]; // key strokes on keyboard that when pressed together open menu. in this case CTRL+OPTION+e
    var lastKeys = {};

    errors.push(e);

    var matches = function(code) {
      var doesMatch = false;

      // CTRL + OPTION + e
      if (code === errorKeys[0] || code === errorKeys[1] || code === errorKeys[2]) {
        doesMatch = true;
      }

      return doesMatch;
    };

    var reset = function() {
      lastKeys = {};
    };

    var triggerError = function(keyEvent) {
      var show = false;
      var keyCode = keyEvent.keyCode;

      if (matches(keyCode)) {
        lastKeys[keyCode] = true;

        if (lastKeys[errorKeys[0]] === true && lastKeys[errorKeys[1]] === true && lastKeys[errorKeys[3]] === true) {
          show = true;
        }
      }

      if (show) {
        lastKeys = {}; // reset
        printErrors();
        window.removeEventListener("keyup", reset);
        window.removeEventListener("keydown", triggerError);
      }
    };

    var printErrors = function() {
      var html = "";

      errors.forEach(function(error) {
        html += "<h4 class='danger'>"+error.message+"</h4>";
        html += "<p class='me-3'>"+error.stack+"</p>";
      });

      errorHTMLWrapper.innerHTML += html;
      errorHTMLWrapper.className = 'well m-1';
      document.getElementById('view-main-wrapper').className = 'view-main-wrapper d-none';
      document.getElementsByClassName('footer-exp')[0].className = 'd-none footer-exp';
    };

    // Event listeners for keystrokes.
    window.addEventListener("keyup", reset);
    window.addEventListener("keydown", triggerError);

    console.log("Recognize didn't load");
    console.error(e.stack);
  }
}

function init(pages) {
  'use strict';

  rLog('Start of init', window.R.ready);

  if (window.R.ready) {
    return;
  }

  R.setHost();
  setJquerySelectors();

  rLog('Start of browserDetector');
  browserDetector();

  rLog('Start of requiredToStart', R.isTurbo);
  requiredToStart();

  R.transition = new Transition();

  R.QueuedScriptsManager.exec();

  loadPage(pages);

  if (Modernizr.touch) {
    $html.addClass("touch");
  }

  initTooltips();
  initCaptcha();

  $window.on('load', function() {
    $body.addClass("ready");
  });

  if (!R.ui.sd && R.ui.ScheduleDemo) {
      R.ui.sd = new R.ui.ScheduleDemo();
  }

  $document.on(R.touchEvent, ".filters button", function() {
    var $this = $(this);
    $this.siblings().removeClass("btn-primary").addClass("btn-link");
    $this.addClass("btn-primary").removeClass("btn-link");
  });

  $document.on(R.touchEvent, ".animate-scroll", function(e) {
    var $el = $(this), href, offset;

    e.preventDefault();

    if ($el.data("href")) {
      href =  $el.data("href");
    } else {
      href = $el.attr("href");
      $el.data("href", href);
    }

    $document.trigger('animate-scroll', $el);

    offset = $(href).offset().top;

    $("html, body").animate({
      scrollTop: (offset - 150) +"px"
    });
  });

  if (Modernizr.ie) {
    $('input[placeholder], textarea[placeholder]').placeholder().each(function() {
      var $this = $(this);
      if ($this.val() === $this.attr("placeholder")) {
        $this.val("");
      }
    });

  }

  $document.on(R.touchEvent, '.exportTable', function() {
    var $table = $(this).next("table");

    var filename = $table.data("filename");
    R.exportTableToCSV.apply(this, [$table, filename+".csv"]);
  });

  /*
   * Fix: Turbolinks 5 triggers a page visit for same-page anchor links (instead of just letting the browser 'jump')
   * https://github.com/turbolinks/turbolinks/issues/75
   *
   * Note: This fix originally broke the "back" behavior when coming back to anchored links
   * which has been partially mitigated later in this file with yet another fix: @see PR #1295
   */
  $document.on('turbo:click', function (event) {
    var target = event.target;
    if (target.getAttribute('href').charAt(0) === '#') {
      return event.preventDefault();
    }
  });

  /* When handling JS views, nonce aren't set properly thus action rendering views via js.erb
     aren't executed. This workaround is implemented to add nonce to jquery script handler which will
     take care of setting nonce value extracted from meta tag as seen from `getCspNonceValue()`.

     Original Implementation of jquery with nonce added
     Ref. https://gitlab.com/gitlab-org/gitlab-foss/-/merge_requests/31839
     `This adds the CSP nonce when jQuery evals JS from Rails JS views.`
   */
  $.ajaxSetup({
    converters: {
      'text script': function (text) {
        jQuery.globalEval(text, {nonce: getCspNonceValue()});
        return text;
      },
    },
    beforeSend: function(xhr, settings) {
      // relay CSRF token from the page for turbolinks requests
      // but not for external requests (eg. giphy)
      let path = settings.url
      let isAbsolutePath = (path.indexOf('http://') === 0 || path.indexOf('https://') === 0)
      if (isAbsolutePath) {
        let xhrHost = new URL(path).origin
        if (xhrHost !== R.host) {
          return; // skip
        }
      }

      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    }
  });

  if (R.ui.Header) {
    if (!window.R.ui.header) {
      R.ui.header = new R.ui.Header();
    } else {
      R.ui.header.addEvents();
    }
  }

  if (!window.R.ajaxify) {
    window.R.ajaxify = new window.R.Ajaxify();
  }

  setTimeout(function() {
    $(".animate-hidden").removeClass("animate-hidden");
    if (window.balanceText) {
      balanceText(".balance-text", {watch: true});
    }
  }, 500);

  window.R.utils.setupSessionInactivityCheckTimer();

  $(".swal-form-link").click(function (e) {
    e.preventDefault();
  });

  R.initTooltips = initTooltips;

  window.R.ready = true;
};


function requiredToStart() {
  let scrollTimer = 0;
  addReadyClass();
  loadLazyImages()
  if (R.isTurbo) {
    return;
  }

  window.localStorage.recognizeweburl = window.location.pathname;

  rLog('Start of checkIfScrolledToHashLink');
  checkIfScrolledToHashLink();

  $document.on(R.touchEvent, "a[data-event], button[data-event]", trackEvent);
  $document.on("submit", 'form[data-event]', trackEvent);
  $document.on("blur", "a[data-ycbm-modal]", trackEvent);

  $document.on("turbo:before-frame-render", function(evt) {
    if(evt.target.delegate.action == 'advance') {
      R.frameAdvance = true;
    }
  });

  // https://turbo.hotwired.dev/reference/events
  $document.on("turbo:before-cache", function() {
    // Current page is about to be saved to cache.
    if(typeof(window.R.checkExpiredSessionIntervalId) !== "undefined"){
      clearInterval(window.R.checkExpiredSessionIntervalId);
      localStorage.removeItem("pingSessionCheckAt");
    }

    let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
    for (var element of scriptTagsToAddNonces) {
      element.setAttribute('nonce', element.nonce);
    }

    removeEventsFromCurrentPage();
    restoreTooltipTitles();

    if(R.frameAdvance) {
      R.frameAdvance = undefined;
    } else {
      destroySelect2Elements();
    }

    closeSwalIfOpen();

    if (R.ui.header) {R.ui.header.removeEvents()}; // !
    if (R.ui.drawer) {R.ui.drawer.close()}; // !

    removeTooltips();
    R.QueuedScriptsManager.reset();
  });

  // https://turbo.hotwired.dev/reference/events
  // A new page is about to be fetched from the server
  $document.on("turbo:before-fetch-request", function(_) {
    $html.addClass("loading");
    R.isTurbo = true;
    $document.off("recognize:init");
    removeTooltips();
  });

  // https://turbo.hotwired.dev/reference/events
  // Page has been fetched from the server (but not rendered)
  $(document.documentElement).on("turbo:before-fetch-response", function() {
    window.localStorage.recognizeweburl = window.location.href;
    $html.removeClass("loading");

    if (window.balanceText) {
      setTimeout(function() {
        balanceText(".balance-text");
      }, 500);
    }

    removeTooltips();
  })

  // https://turbo.hotwired.dev/reference/events
  // Page has been fetched (local / remote) and is about to be rendered
  $(document.documentElement).on("turbo:before-render", function() {
    window.R.ready = false;
  });

  // This is to fix the Turbolinks Issue where the back button doesn't work when returning to anchored paths.
  // https://github.com/turbolinks/turbolinks/pull/285
  // and https://github.com/Recognize/recognize/issues/1208
  window.addEventListener('popstate', popStateTurbolinksHashFix, false);

  $document.on(R.touchEvent, "a", function() {
    if (this.href.indexOf("#") > -1) {
      window.R.hasClickedHashLink = true;
    }
  });

  $document.on(R.touchEvent, ".goBack", function() {
      // Get the default back URL from the data attribute of the clicked element
      var defaultBackUrl = $(this).data('default-url');

      // Attempt to navigate back with history API if possible
      if (history.length > 2) {
        history.back();
      } else if (defaultBackUrl) {
        // Use Turbo to navigate to the default back URL if history is not sufficient
        Turbo.visit(defaultBackUrl);
      } else {
        // Fallback option to navigate back using the browser's history
        // This might be redundant with the above history.back(),
        // but it's here to show the option explicitly.
        history.back();
      }
  });

  if ( $("body.logout").length ) {
    $window.on("scroll.once", function() {
      if ($window.scrollTop() > 50) {
        $(document.body).addClass("scrolled");
        $window.off("scroll.once");
      } else {
        $(document.body).removeClass("scrolled");
      }
    });

    $window.scroll(function() {
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(function() {
        if ($window.scrollTop() > 50) {
          $(document.body).addClass("scrolled");
        } else {
          $(document.body).removeClass("scrolled");
        }
      }, 250);
    });
  }

  // https://github.com/hotwired/turbo/issues/294#issuecomment-877842232
  document.addEventListener('turbo:before-fetch-request', (event) => {
    // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
    event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
    event.detail.fetchOptions.headers['X-Turbo-Nonce'] = $("meta[name='csp-nonce']").prop('content')
  });
}

function addReadyClass() {
  setTimeout(function () {
    $body.addClass("ready");
  }, 1000);
}

function loadLazyImages() {
  if (!$.fn.Lazy) return;

  R.utils.loadLazyImages();

  $('#navbar .dropdown').one('show.bs.dropdown', function (_) {
    $(this).find('.lazy').lazy();
  });
}

function loadPage(pages) {
  console.log('Loading page...');
  const pageID = document.body.getAttribute("data-page-id");

  // This is a catch for pages that don't have a page ID, for example static error pages
  if(!pageID) {
    console.log('No page ID found');
    return
  }
  const pagePath = fetchPagePathFromPageID(pageID)
  const page = pages[pagePath];
  if (page) {
    R.currentPage = new page.default();
    console.log('Page loaded:', pagePath);
  } else if (pageID.match(/^company_admin/)) {
    R.currentPage = new CompanyAdmin();
    console.log('Company Admin page loaded');
  } else {
    console.log(`Page not found: ${pagePath}`);
  }
}

function initCaptcha() {
   /*
   * This init is skipped for the majority of specs involving captcha forms, which have Recaptcha disabled.
   * This prevents unnecessary 3p requests.
   */
  if ($(".captcha-trigger-button").length && !gon.skip_captcha) {
    return R.captchaInstance = new Captcha();
  }
}

function checkIfScrolledToHashLink() {
  var href = window.location.href;
  if (href.indexOf("#") > -1) {
    setTimeout(function() {
      var el = href.substring(href.indexOf("#"), href.length),
        $el, offsetTop;
      if (el === "#") {
        return;
      }

      if (el.indexOf("?") > -1) {
        el = el.substring(0, el.indexOf("?"));
      }
      $el = $(el);
      if (!$el.length) {
        return;
      }

      offsetTop = $el.offset().top - 100;
      if (offsetTop - $window.scrollTop() > 300) {
        $('html, body').animate({
          scrollTop: offsetTop
        }, 800);
      }
    }, 2000);
  }
}

function trackEvent(e) {
  var $this = $(e.target);
  var data = {};

  var category = $this.data('category') || $body.attr("id");
  var event = $this.data('event');
  var value = $this.data('value');

  if (category) data.category = category;
  if (event) data.event = event;
  if (value) data.value = value;

  if (window.analytics) {
    window.analytics.track(event, data);
  }
}

function removeEventsFromCurrentPage() {
  if (R.currentPage && R.currentPage.removeEvents) {
    R.currentPage.removeEvents();
  }

  $document.off(R.touchEvent, ".animate-scroll");
}

function restoreTooltipTitles() {
  $("[data-original-title]").each(function() {
    let $this = $(this);
    $this.attr("title", $this.data("original-title"));
  });
}

function destroySelect2Elements() {
  $('.select2-hidden-accessible').each(function() {
    let $elem = $(this);
    if ($elem.data('select2'))
      $elem.select2('destroy');
  });
}

function closeSwalIfOpen() {
  if (typeof Swal === 'undefined' || !Swal.isVisible()) return;

  // Disable animation, as it causes the swal removal code to be wrapped in an async callback that is never executed
  // unset animation ref: https://github.com/sweetalert2/sweetalert2/issues/2089#issuecomment-711151323
  // alternative would be to remove the animation class manually however unsetting via swal seems more reliable
  // as this approach was recommended by auther in the above ref link.
  Swal.update({ hideClass: {popup: '', backdrop: ''} });
  Swal.close();
}

function removeTooltips() {
  $(".tooltip").remove();
  setTimeout(function() {
      $(".tooltip").remove();
  }, 500);
}

function popStateTurbolinksHashFix(_) {
  var href = window.location.href;
  // The popstate event is fired each time when the current history entry changes.

  // If the incoming page, already window.location.href in this situation funny enough, is different from the last page (localStorage.recognizeweburl) AND has a # in the URL, then we have to reload the page to make this work I'm afraid
  // Also this bug only occurs when someone clicks a hash link, not when the page loads and has a hash link.
  if ( (window.location.pathname.indexOf(window.localStorage.recognizeweburl) == -1) && (href.indexOf("#") > -1) && window.R.hasClickedHashLink) {
    window.removeEventListener('popstate', popStateTurbolinksHashFix);
    window.R.hasClickedHashLink = undefined;
    Turbo.visit(href);
  }
}

function getCspNonceValue() {
  var metaTag = document.querySelector('meta[name=csp-nonce]');
  return metaTag && metaTag.content;
}

function setJquerySelectors() {
  window.$body = $(document.body);
  window.body = document.body;
  window.$document = $(document);
  window.$window = window.$window || $(window);
  window.$html = window.$html || $("html");
}
